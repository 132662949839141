import { default as _91_46_46_46slug_93xcQkAlp3bUMeta } from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/[...slug].vue?macro=true";
import { default as archiveOiRs9erRQLMeta } from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/archive.vue?macro=true";
import { default as _91_46_46_46slug_93Ckxc5K7OIkMeta } from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/event/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93O1cPxLJicLMeta } from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/events/[...slug].vue?macro=true";
import { default as indexLRCZ0StHSnMeta } from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93JoxPj0qFSFMeta } from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/news/[...slug].vue?macro=true";
import { default as index7YrbzsC88EMeta } from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/news/index.vue?macro=true";
import { default as styleguiderH9Ji6nD4KMeta } from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/styleguide.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "archive",
    path: "/archive",
    component: () => import("/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/archive.vue").then(m => m.default || m)
  },
  {
    name: "event-slug",
    path: "/event/:slug(.*)*",
    component: () => import("/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/event/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-slug",
    path: "/events/:slug(.*)*",
    component: () => import("/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/events/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug(.*)*",
    component: () => import("/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "styleguide",
    path: "/styleguide",
    component: () => import("/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/pages/styleguide.vue").then(m => m.default || m)
  }
]