import revive_payload_client_4sVQNw7RlN from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import gsapPlugin_HlwIIyl85L from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/.nuxt/gsapPlugin.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import date_format_ii1UKogrOQ from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/plugins/date-format.js";
import entry_link_YgS5hXCYnt from "/Users/bjarkerasmussen/Sites/localhost/sydhavnteater/frontend/plugins/entry-link.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  gsapPlugin_HlwIIyl85L,
  chunk_reload_client_UciE0i6zes,
  date_format_ii1UKogrOQ,
  entry_link_YgS5hXCYnt
]