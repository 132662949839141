<template>
    <div class="newsletter component bg-green">
        <div class="grid grid-cols-12">
            <div class="col-span-12 md:col-span-9">
                <h2 class="headline-big">Tilmeld dig vores nyhedsbrev <span class="font-serif">/</span> <span class="text-white">Sign up for our newsletter</span></h2>
            </div>
        </div>
        <div class="grid grid-cols-12">
            <div class="col-span-10 md:col-span-5 text-base-serif mt-6">
                <p>Udfyld dine oplysninger herunder, så du bliver den første til at modtage nyheder, gratis forestillinger og andre særlige events.</p>
            </div>
        </div>
        <!-- <div class="grid grid-cols-12 mt-14 md:mt-22">
            <div class="col-span-12 md:col-span-4">
                <input type="text" placeholder="Navn" class="rounded-none uppercase w-full border-gray-darker border-b-[2px] bg-transparent text-black placeholder:text-black p-0 text-sm outline-0">
            </div>
            <div class="col-span-12 md:col-span-2">
                <input type="text" placeholder="Postnummer" class="max-md:headline-sm rounded-none uppercase w-full border-gray-darker border-b-[2px] bg-transparent text-black placeholder:text-black p-0 text-sm outline-0">
            </div>
            <div class="col-span-12 md:col-span-6">
                <input type="text" placeholder="Skriv din email" class="rounded-none uppercase w-full border-gray-darker border-b-[2px] bg-transparent text-black placeholder:text-black p-0 text-sm outline-0">
            </div>
        </div> -->


        <div id="mc_embed_shell">
            <div id="mc_embed_signup">
                <form action="https://sydhavnteater.us15.list-manage.com/subscribe/post?u=b4e0d88b807eaeb28437782ad&amp;id=63a1ff2978&amp;f_id=009ea2e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                    <div id="mc_embed_signup_scroll" class="relative">

                        <div class="grid grid-cols-12 mt-14 md:mt-22">
                            <div class="col-span-12 md:col-span-4">
                                <div class="mc-field-group">
                                    <input placeholder="Navn" type="text" name="FNAME" class="max-md:headline-sm rounded-none uppercase w-full border-gray-darker border-b-[2px] bg-transparent text-black placeholder:text-black p-0 py-1.5 text-sm outline-0 text" id="mce-FNAME" value="">
                                </div>
                            </div>
                            <div class="col-span-12 md:col-span-2">
                                <div class="mc-field-group">
                                    <input placeholder="Postnummer" type="text" name="MMERGE3" class="max-md:headline-sm rounded-none uppercase w-full border-gray-darker border-b-[2px] bg-transparent text-black placeholder:text-black p-0 py-1.5 text-sm outline-0 text" id="mce-MMERGE3" value="">
                                </div>
                            </div>
                            <div class="col-span-12 md:col-span-6 relative">
                                <div class="mc-field-group">
                                    <input placeholder="Skriv din email" type="email" name="EMAIL" class="max-md:headline-sm rounded-none uppercase w-full border-gray-darker border-b-[2px] bg-transparent text-black placeholder:text-black p-0 py-1.5 text-sm outline-0 required email" id="mce-EMAIL" value="" required="">
                                </div>

                                <button id="mc-embedded-subscribe" class="button max-md:mt-12 md:absolute right-0 top-0 max-md:headline-sm uppercase bg-transparent text-black p-0 text-sm outline-0 z-50 cursor-pointer flex flex-row items-center gap-x-4">
                                    Tilmeld nyhedsbrev
                                    <svg class="w-6 h-6 md:w-9 md:h-9" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="17.5" cy="17.5" r="16.5" stroke="black" stroke-width="2"/>
                                    <path d="M17.8343 24.7274L17.8345 24.7276C17.978 24.871 18.1726 24.9515 18.3755 24.9515C18.5784 24.9515 18.773 24.871 18.9166 24.7276L18.9167 24.7275L25.1042 18.54L25.1043 18.5399C25.2477 18.3963 25.3282 18.2017 25.3282 17.9989C25.3282 17.796 25.2477 17.6014 25.1043 17.4578L25.1042 17.4577L18.9167 11.2702L18.9168 11.2701L18.9103 11.2641C18.7652 11.1288 18.5732 11.0552 18.3749 11.0587C18.1765 11.0622 17.9873 11.1426 17.847 11.2828C17.7067 11.4231 17.6264 11.6124 17.6229 11.8107C17.6194 12.0091 17.693 12.201 17.8283 12.3462L17.8281 12.3463L17.8344 12.3525L22.7143 17.2332L9.43803 17.2332C9.23498 17.2332 9.04024 17.3139 8.89666 17.4575C8.75307 17.6011 8.67241 17.7958 8.67241 17.9989C8.67241 18.2019 8.75307 18.3967 8.89666 18.5402C9.04024 18.6838 9.23498 18.7645 9.43803 18.7645L22.7143 18.7645L17.8344 23.6452L17.8343 23.6453C17.6909 23.7889 17.6104 23.9835 17.6104 24.1864C17.6104 24.3892 17.6909 24.5838 17.8343 24.7274Z" fill="black" stroke="black" stroke-width="0.5"/>
                                    </svg>
                                </button>
                            </div>

                            <div class="col-span-12 relative">
                                <div id="mce-responses" class="clear">
                                    <div class="response max-md:headline-sm text-sm" id="mce-error-response" style="display: none;"></div>
                                    <div class="response max-md:headline-sm text-sm" id="mce-success-response" style="display: none;"></div>
                                </div>
                            </div>
                        </div>

                        <div aria-hidden="true" style="position: absolute; left: -5000px;">
                            <input type="text" name="b_b4e0d88b807eaeb28437782ad_63a1ff2978" tabindex="-1" value="">
                        </div>
                    </div>
                </form>
            </div>
        </div>


    </div>
</template>

<script setup>
onMounted(() => {
    if (process.client) {
        const script = document.createElement('script');
        script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
        script.type = "text/javascript";

        script.onload = function() {
            console.log('loaded')
            window.fnames = new Array(); window.ftypes = new Array();fnames[1]='FNAME';ftypes[1]='text';fnames[3]='MMERGE3';ftypes[3]='text';fnames[0]='EMAIL';ftypes[0]='email';fnames[2]='LNAME';ftypes[2]='text';fnames[4]='LINKID';ftypes[4]='text';fnames[5]='MMERGE5';ftypes[5]='text';fnames[6]='MMERGE6';ftypes[6]='text';
            
            var $mcj = jQuery.noConflict(true);

            $mcj.extend($mcj.validator.messages, {
                required: "Dette felt er påkrævet.",
                remote: "Please fix this field.",
                email: "Indtast en gyldig email-adresse.",
                url: "Indtast en gyldig URL.",
                date: "Indtast en gyldig dato.",
                dateISO: "Indtast en gyldig dato (ISO).",
                number: "Indtast et gyldigt nummer.",
                digits: "Indtast kun cifre.",
                creditcard: "Indtast et gyldigt kreditkortnummer.",
                equalTo: "Indtast den samme værdi igen.",
                maxlength: $mcj.validator.format("Indtast højst {0} tegn."),
                minlength: $mcj.validator.format("Indtast mindst {0} tegn."),
                rangelength: $mcj.validator.format("Indtast mindst {0} og højst {1} tegn."),
                range: $mcj.validator.format("Angiv en værdi mellem {0} og {1}."),
                max: $mcj.validator.format("Angiv en værdi, der er mindre end eller lig med {0}."),
                min: $mcj.validator.format("Angiv en værdi, der er større end eller lig med {0}.")
            })

            console.log('$mcj', $mcj)
        }

        document.getElementById('mc_embed_shell').appendChild(script);

        // setTimeout(() => {
            // (function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[1]='FNAME';ftypes[1]='text';fnames[3]='MMERGE3';ftypes[3]='text';fnames[0]='EMAIL';ftypes[0]='email';fnames[2]='LNAME';ftypes[2]='text';fnames[4]='LINKID';ftypes[4]='text';fnames[5]='MMERGE5';ftypes[5]='text';fnames[6]='MMERGE6';ftypes[6]='text';}(jQuery));var $mcj = jQuery.noConflict(true);
        // }, 1000)
    }
});
</script>

<style lang="scss" scoped>
.newsletter.component {
    @apply pt-[3rem] md:pt-[5rem];
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    transition: background-color 9999999s ease-in-out 0s;
}

#mc_embed_signup :deep(div.mce_inline_error) {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
}
</style>