<template>
	<div class="bg-gray-light">
		<span class="block spacing-top ml-20" ref="spacer" v-if="doAnimation"></span>
		<header class="relative z-80 top-0 left-0 w-full flex flex-row justify-between" ref="header">
			<h1 :class="{'is-home': doAnimation, 'opacity-0': logoLoading}" class="opacity-100 text-xs fixed top-0 px-4 md:px-20 py-4 md:py-19 z-30"><div ref="logo" class="transition-opacity duration-300" :class="{'opacity-0': !headerMounted, 'opacity-100': headerMounted}"><NuxtLink to="/"><ElementLogo/></NuxtLink></div></h1>

			<!-- <button class="fixed top-0 right-0 px-20 py-19 z-50 outline-none" @click="menuIsOpen = !menuIsOpen">
				<svg xmlns="http://www.w3.org/2000/svg" width="107" height="34" fill="none" viewBox="0 0 107 34"><path stroke="#000" stroke-width="4" d="M107 2H0M107 17H0M107 32H0"/></svg>
			</button> -->

			<button @click="menuIsOpen = !menuIsOpen" class="transition-opacity duration-300 hamburger hamburger--slider fixed top-0 right-0 px-4 md:px-20 py-4 md:py-19 z-50" :class="{'is-active': menuIsOpen, 'opacity-0': !headerMounted, 'opacity-100': headerMounted}">
				<span class="hamburger-box">
					<span class="hamburger-inner"></span>
				</span>
			</button>

			<nav class="fixed h-0 top-0 left-0 w-full z-20" v-if="data">
				<button :class="{'opacity-0 pointer-events-none': !menuIsOpen, 'opacity-25': menuIsOpen}" class="transition duration-400 fixed top-0 left-0 w-full h-full bg-gray-darker z-10" @click="menuIsOpen = false"></button>
				<div :class="{'-translate-y-menu': !menuIsOpen}" class="transition duration-400 md:h-[92vh] px-4 md:px-20 py-4 md:py-19 overflow-auto bg-white w-full relative z-20">
					<ul class="headline-md flex flex-col gap-[0.4rem] md:gap-4 mt-22">
						<li v-for="(entry, index) in data[0].mainMenu" :key="index">
							<NuxtLink :to="`/${entry.entryLink[0].uri}`" class="group transition duration-200 hover:text-green">{{entry.title}} <span v-if="entry.titleEnglish" class="font-serif text-black group-hover:text-green transition duration-200">/</span> <span class="text-gray-dark group-hover:text-green transition duration-200" v-if="entry.titleEnglish">{{entry.titleEnglish}}</span></NuxtLink>
						</li>
					</ul>

					<ul class="text-sm uppercase flex flex-col mt-21 mb-8">
						<li v-for="(entry, index) in data[0].secondaryMenu" :key="index">
							<NuxtLink :to="`/${entry.entryLink[0].uri}`" class="group transition duration-200 hover:text-green">{{entry.title}} <span v-if="entry.titleEnglish" class="font-serif text-black group-hover:text-green transition duration-200">/</span> <span class="text-gray-dark group-hover:text-green transition duration-200" v-if="entry.titleEnglish">{{entry.titleEnglish}}</span></NuxtLink>
						</li>
					</ul>
				</div>
			</nav>
		</header>
	</div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
	data: Object
})
const { $gsap, $ScrollTrigger } = useNuxtApp()

const menuIsOpen = ref(false)
const route = useRoute()

const spacer = ref(null)
const header = ref(null)
const logo = ref(null)
const logoLoading = ref(true)
const headerMounted = ref(false)

const doAnimation = ref(route.path == '/')

let tl
let savedProgress = 0

watch(() => route.path, async(val) => {
	menuIsOpen.value = false

	await nextTick()

	if (val != '/') {
		doAnimation.value = false
		if (tl) {
			tl.tweenTo(1, {
				ease: 'none',
				duration: 0,
				onComplete: () => {
					tl.kill()
					tl = null
				}
			});
		}
	}
}, { immediate: true, deep: true })

watch(menuIsOpen, () => {
	if (menuIsOpen.value) {
		document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
		document.body.style.overflow = 'hidden';
	} else {
		document.body.style.overflow = '';
		document.body.style.paddingRight = '';
	}

	if (tl && menuIsOpen.value) {
		savedProgress = tl.progress()
		tl.tweenTo(tl.duration(), {
			ease: 'none',
			duration: 0.2
		});
	} else if (tl && !menuIsOpen.value) {
		tl.tweenTo(savedProgress, {
			ease: 'none',
			duration: 0.2
		});
	}
})

const setLogoAnimation = () => {
	tl = $gsap.timeline({
        scrollTrigger: {
            trigger: spacer.value,
            start: "top",
            end: "105%",
            scrub: true,
        },
    })

    tl.to(logo.value, {
        width: isLargeScreen.value ? '152px' : '98px',
        duration: 1,
		ease: "none",
    }, 0)

	logoLoading.value = false

	if (savedProgress) {
		tl.progress(savedProgress)
	}

	setTimeout(() => {
		headerMounted.value = true
	}, 300)
}

let doResizeResult = async () => {
	if (tl) {
		logoLoading.value = true
		tl.kill()
		tl = null
		logo.value.style.width = ''
		await nextTick()
		setLogoAnimation()
	}
}

let debounce = (callback, delay) => {
	let myTimeout;
	return () => {
		clearTimeout(myTimeout);
		myTimeout = setTimeout(() => {
			callback()
		}, delay);
	};
};

let doDebounce = debounce(() => doResizeResult(), 800)
const isLargeScreen = ref(null)
onMounted(() => {
	if (process.client) {
		isLargeScreen.value = ref(window.matchMedia('(min-width: 1024px)').matches);

		const updateScreenSize = () => {
			isLargeScreen.value = window.matchMedia('(min-width: 1024px)').matches;
		};

		window.addEventListener('resize', updateScreenSize);
	}

    if (route.path == '/' && isLargeScreen.value != null) {
		setLogoAnimation()
	} else {
		headerMounted.value = true
	}

	if (process.client) {
		window.addEventListener('resize', () => doDebounce());
		window.removeEventListener("resize", () => doDebounce(), true);
	}
})
</script>

<style lang="scss" scoped>
.spacing-top {
	width: calc(100% - 288px);
	padding-bottom: 21.5%;
}

h1:not(.is-home) > div {
	width: 152px;

	@media (max-width: 1024px) {
		width: 98px;
	}
}

h1.is-home {
	width: calc(100% - 66px);
	margin-top: -1px;

	@media (min-width: 1024px) {
		width: calc(100% - 12%);
		margin-top: 0;
	}
}
</style>