export default defineNuxtPlugin(() => {
    const entryLink = (entry) => {
        if (entry.type === 'videoEmbed') {
            return entry.embedCode
        }
    }
	return {
		provide: { entryLink },
	}
})
