import { defineNuxtPlugin } from '#app/nuxt'
import { LazyCalendarFilter, LazyContacts, LazyDataTable, LazyElementAccordion, LazyElementButton, LazyElementCard, LazyElementCardContact, LazyElementCardDetailed, LazyElementCardNews, LazyElementLogo, LazyEntryBlock, LazyFeaturedEvents, LazyFeaturedNews, LazyFoldOuts, LazyFooter, LazyGallery, LazyHeader, LazyHero, LazyHeroEvent, LazyImage, LazyLocations, LazyMedia, LazyNewsletter, LazyPartnersBlock, LazyPassword, LazyText, LazyVideoEmbed } from '#components'
const lazyGlobalComponents = [
  ["CalendarFilter", LazyCalendarFilter],
["Contacts", LazyContacts],
["DataTable", LazyDataTable],
["ElementAccordion", LazyElementAccordion],
["ElementButton", LazyElementButton],
["ElementCard", LazyElementCard],
["ElementCardContact", LazyElementCardContact],
["ElementCardDetailed", LazyElementCardDetailed],
["ElementCardNews", LazyElementCardNews],
["ElementLogo", LazyElementLogo],
["EntryBlock", LazyEntryBlock],
["FeaturedEvents", LazyFeaturedEvents],
["FeaturedNews", LazyFeaturedNews],
["FoldOuts", LazyFoldOuts],
["Footer", LazyFooter],
["Gallery", LazyGallery],
["Header", LazyHeader],
["Hero", LazyHero],
["HeroEvent", LazyHeroEvent],
["Image", LazyImage],
["Locations", LazyLocations],
["Media", LazyMedia],
["Newsletter", LazyNewsletter],
["PartnersBlock", LazyPartnersBlock],
["Password", LazyPassword],
["Text", LazyText],
["VideoEmbed", LazyVideoEmbed],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
