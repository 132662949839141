export default defineNuxtPlugin(() => {
    function pad(str) {
        if (str.length === 2) return str;
        return pad('0' + str);
    }
    
    function padDate(str, delimiter) {
        return str.split(delimiter).map(function (el) {
            if (el.length < 2) return pad(el);
            return el;
        }).join(delimiter);
    }
    
    const formatDate = (date, short = false) => {
        if (short) {
            return padDate(new Intl.DateTimeFormat('da-DK', { month: 'numeric', day: 'numeric', hour12: false }).format(date), '.');
        }
        return padDate(new Intl.DateTimeFormat('da-DK', { month: 'numeric', day: 'numeric', year: 'numeric', hour12: false }).format(date), '.');
    }
    
    const dateFormat = (from, to = null) => {
        let dateFrom = new Date(from);
        let dateTo = to ? new Date(to) : null;

        if (from == to) {
            return formatDate(dateFrom)
        }

        if (to === null) {
            return formatDate(dateFrom)
        } else {
            if (dateFrom.getFullYear() !== dateTo.getFullYear()) {
                dateTo = formatDate(dateTo)
                dateFrom = formatDate(dateFrom, true)
            } else {
                dateTo = formatDate(dateTo)
                dateFrom = formatDate(dateFrom, true)
            }
        }
        
        return `${dateFrom}-${dateTo}`
    }
	return {
		provide: { dateFormat },
	}
})
